import React from 'react';

import { AnnoucementBarStyles, Inner } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ButtonComponent } from '../Button/Button';

interface Props {
  Image: object;
  Content?: string;
  AdditionalClass?: string;
  Link?: object;
}

export const AnnoucementBar = ({ Image, AdditionalClass, Content, Link, ...props }: Props) => {
  return (
    <AnnoucementBarStyles className={`Component-AnnoucementBar ${AdditionalClass}`} {...props}>
      <div className={'container'}>
        <Inner className={`${AdditionalClass}`} target={Link.target} href={Link.link}>
          {Image && <img alt={''} className={`${AdditionalClass}`} src={Image} />}
          <div className={'text-wrap'} dangerouslySetInnerHTML={createMarkUp(Content)}></div>
          {Link && (
            <div className={'button-wrap'}>
              <ButtonComponent Theme={'stripped'} Label={Link.label} Link={Link.link} Target={Link.target} />
            </div>
          )}
        </Inner>
      </div>
    </AnnoucementBarStyles>
  );
};
